import React from "react"

import Layout from "../components/layout"
import { FooterAlternate1 } from "../components/footer"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Contact Us" />
    
    <article className="article">
        <header className="header header--basic article__header">
            <div className="header__content">
                <div className="container">
                    <div className="row">
                        <div className="header__inner col-md-9 col-lg-7 col-lg-offset-1">
                            <h1 className="header__title">Contact us</h1>
                            <h3 className="header__caption">Please email: inquiries@salestribe.com</h3>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <div className="container" id="article-content">
            <div className="row">
                <div className="article__body col-sm-12 col-md-8 col-lg-offset-1 col-lg-7">
                    <img alt="Main Diagram" className="img-responsive" height="188" src="../media/images/Diagram.width-647.png" width="647" />
                    <div className="rich-text">
                        <p>
                            <br/>
                        </p>
                        <p>
                            <br/>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </article>

    <FooterAlternate1 />
  </Layout>
)

export default IndexPage